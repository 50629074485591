import { GlobalOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Popover, message } from 'antd';

import Title from 'antd/es/typography/Title';
import { createBioBtn, deleteBioBtn, fetchAllBios, saveBioBtn } from '../../../../features/api/biosAPI';
import { setLanguage } from '../../../../features/languageSlice';
import BioItemCard from './BioItemCard';
import BioItemInfoPopover from './BioItemInfoPopover';


const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 600px;
  max-height: 680px;
  overflow: hidden;
  overflow-y: auto;
`;

const BioCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 25px;
  padding:30px;
`;

const HeaderControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;


export default function BioManagerModal({
  isVisible,
  status,
  handleConfirm,
  handleClose,
  handleAfterClose,
}) {
  const dispatch = useDispatch();

  const [bioButtons, setBioButtons] = useState([]);
  const [selectedBio, setSelectedBio] = useState();



  const [isCreateBio, setIsCreateBio] = useState(false);
  const [isCreatingBio, setIsCreatingBio] = useState(false);


  const loadAllButtons = async () => {
    try {
      const { data } = await fetchAllBios();
      setBioButtons(data);
    } catch (error) {
      console.log(error);
    }
  }

  const createItem = async (bioData) => {
    try {
      setIsCreatingBio(true);
      const { data } = await createBioBtn(bioData);
      const updatedBtns = [...bioButtons, { ...bioData, id: data }];
      setBioButtons(updatedBtns);
      setIsCreatingBio(false);
      closeCreateBio();
      message.success("Bio created successfully");
    } catch (error) {
      setIsCreatingBio(false);
      message.error("Bio create failed " + error);
    }
  }

  const updateItem = (bioToUpdate) => {
    return new Promise(async (resolve, reject) => {
      try {
        await saveBioBtn(bioToUpdate);
        const updatedBtns = [
          ...bioButtons.filter((btn) => btn.id !== bioToUpdate.id),
          { ...bioToUpdate }
        ];
        setBioButtons(updatedBtns);
        message.success("Bio updated successfully");
        resolve();
      } catch (error) {
        console.log(error);
        reject();
        message.error("Bio update failed " + error);
      }
    });


  }

  const deleteItem = async (id) => {
    try {
      await deleteBioBtn(id);
      const updatedBtns = bioButtons.filter((btn) => btn.id !== id);
      setBioButtons(updatedBtns);
    } catch (error) {
      console.log(error);
    }
  }


  const openCreateBio = () => {
    setIsCreateBio(true);
  }

  const closeCreateBio = (withLoad = false) => {
    setIsCreateBio(false);
  }


  const { language } = useSelector(
    (state) => state.language
  );

  const getDirection = () => {
    return language === "he" ? "rtl" : "ltr";
  }

  const toggleLanguage = () => {
    const lang = language === "he" ? "en" : "he";
    dispatch(setLanguage(lang));
  }


  useEffect(() => {
    if (isVisible) {
      loadAllButtons();
    }
  }, [isVisible]);

  return (
    <>
      <Modal
        keyboard
        style={{
          direction: getDirection(),
        }}
        open={isVisible}
        onOk={handleConfirm}
        onCancel={handleClose}
        okText={"Save"}
        cancelText={'Cancel'}
        confirmLoading={status === 'loading'}
        okButtonProps={{ type: 'primary' }}
        width={'80%'}
        footer={[
          <Popover
            open={isCreateBio}
            content={
              <BioItemInfoPopover
                bioItem={undefined}
                createItem={createItem}
                closeMe={closeCreateBio}
              />
            }
            title="Bio Info"
            trigger="click"
          >
            <Button
              key="submit"
              type="primary"
              loading={isCreatingBio}
              onClick={openCreateBio}
            >
              Create
            </Button>
          </Popover>]}

      >
        <div>
          <HeaderControlsWrapper>
            <Title style={{ margin: 0 }} level={4}>{`Manage Biographies`}</Title>
            <Button
              onClick={toggleLanguage}
              className='create-btn'
              icon={<GlobalOutlined />}
            >
              {language === "en" ? "he" : "en"}
            </Button>

          </HeaderControlsWrapper>
          <ContentWrapper>
            <BioCardsWrapper>
              {bioButtons.length > 0 && bioButtons.map((bio) =>
                <BioItemCard
                  key={bio.id}
                  bio={bio}
                  language={language}
                  updateItem={updateItem}
                  deleteItem={deleteItem}
                />
              )}

            </BioCardsWrapper>
          </ContentWrapper>

        </div>

      </Modal >

    </>
  );
}
